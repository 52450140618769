import React, { useEffect, useRef } from "react";

const SuccessCasesDesk = () => {
  const carouselRef = useRef(null);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      let animationFrame;
      const scrollCarousel = () => {
        if (carousel.scrollLeft >= carousel.scrollWidth / 2) {
          carousel.scrollLeft = 0;
        } else {
          carousel.scrollLeft += 1;
        }
        animationFrame = requestAnimationFrame(scrollCarousel);
      };
      animationFrame = requestAnimationFrame(scrollCarousel);
      return () => cancelAnimationFrame(animationFrame);
    }
  }, []);

  return (
    <div class="cases-section">
    <div 
      className="bg-black-200 mx-auto mt-[-100px] flex flex-col items-center justify-center text-white w-screen "
      style={{
        background: `radial-gradient(circle 250px at 10% 40%,rgba(42, 42, 100, 0.81), rgba(0, 255, 128, 0.1), transparent 70%), 
                     radial-gradient(circle 250px at 70% 50%, rgba(42, 42, 100, 0.7), rgba(0, 255, 128, 0.2), transparent 90%),
                     linear-gradient(to bottom, #101010, #101010`
      }}
    >
      <div className="relative text-center py-10">
        <span className=" text-white text-6xl font-medium font-['Montserrat']">
          Our <span className="text-green-400 text-5xl font-medium font-['Montserrat']">Success</span> Cases
        </span>
        <div className="mx-auto p-4 w-[792px] h-[52px] text-white text-xl font-normal font-['Montserrat']">
          We empower Businesses with Scalable Design and Marketing Solutions, Driven by Human Expertise and Advanced AI for Unmatched Brand Growth.
        </div>
      </div>

      {/* Carousel Section */}
      <div className="carousel wb-car overflow-hidden relative p-5 w-full max-w-6xl mx-auto" ref={carouselRef}>
        <div className="carousel-track flex gap-6 w-max">
          {["logo21.jpg", "logo22.png", "logo23.png", "logo24.png", "logo25.png", "logo26.svg", "logo27.svg", "logo28.svg"]
            .concat(["logo21.jpg", "logo22.png", "logo23.png", "logo24.png", "logo25.png", "logo26.svg", "logo27.svg", "logo28.svg"])
            .map((logo, index) => (
              <div key={index} className="carousel-item flex-shrink-0 w-35 h-24">
                <img
                  src={logo}
                  alt={`Logo ${index % 8 + 1}`}
                  className="w-full h-full object-cover rounded-lg shadow-lg"
                />
              </div>
            ))}
        </div>
      </div>

      {/* Grid Section */}
      <div className=" relative h-[40vw] scc flex p-5 items-start px-4 bg-contain bg-no-repeat bg-center" >

        
            <div className="mr-[31px]">
              <div  
                
                className="w-[15vw] sc1 h-[27vw] grid-item relative overflow-hidden border-2 border-green-500 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out"
              >
                <img
                  src="Project-1.png"
                  alt="Super Burger"
                  className="w-full h-full object-cover bg-white bg-opacity-30 backdrop-blur-md rounded-t-lg transition-transform duration-300 ease-in-out hover:scale-125"
                />
                
              </div>
              <div className="relative bg-opacity-80 text-white text-left mt-[20px]">
                  <div className="left-0 top-[19px] absolute text-white text-3xl font-semibold font-['Montserrat']">Super Burger</div>
                  <div className="left-0 top-0 absolute text-emerald-400 text-sm font-normal font-['Figtree']">Social Media Manager</div>
              </div>
            </div>
            <div className="mr-[31px]">
              <div  
                
                className="w-[12vw] sc2 h-[22vw] grid-item relative overflow-hidden border-2 border-green-500 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out"
              >
                <img
                  src="Project-3.png"
                  alt="Energy Five"
                  className="w-full h-full object-cover bg-white bg-opacity-30 backdrop-blur-md rounded-t-lg transition-transform duration-300 ease-in-out hover:scale-125"
                />
                
              </div>
              <div className="relative  bg-opacity-10 text-white text-left mt-[20px]">
                  <div className="left-0 top-[19px] absolute text-white text-3xl font-semibold font-['Montserrat']">Energy Five</div>
                  <div className="left-0 top-0 absolute text-emerald-400 text-sm font-normal font-['Figtree']">Branding</div>
              </div>
            </div>
            <div className="mr-[31px] ">
              <div  
                
                className="w-[22vw] sc3 h-[15vw] grid-item relative overflow-hidden border-2 border-green-500 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out"
              >
                <img
                  src="Project-2.png"
                  alt="Street Food"
                  className="w-full h-full object-cover bg-white bg-opacity-30 backdrop-blur-md rounded-t-lg transition-transform duration-300 ease-in-out hover:scale-125"
                />
                
              </div>
              <div className="relative bg-opacity-80 text-white text-left mt-[20px]">
                  <div className="left-0 top-[19px] absolute text-white text-3xl font-semibold font-['Montserrat']">Energy Five</div>
                  <div className="left-0 top-0 absolute text-emerald-400 text-sm font-normal font-['Figtree']">Marketing 360</div>
              </div>
            </div>
            <div className="mr-[31px]">
              <div  
                
                className="w-[18vw] sc4 h-[26vw] grid-item relative overflow-hidden border-2 border-green-500 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out"
              >
                <img
                  src="Project-4.png"
                  alt="Tasty Passion"
                  className="w-full h-full object-cover bg-white bg-opacity-30 backdrop-blur-md rounded-t-lg transition-transform duration-300 ease-in-out hover:scale-125"
                />
                
              </div>
              <div className="relative bg-opacity-80 text-white text-left mt-[20px]">
                  <div className="left-0 top-[19px] absolute text-white text-3xl font-semibold font-['Montserrat']">Tasty Passion</div>
                  <div className="left-0 top-0 absolute text-emerald-400 text-sm font-normal font-['Figtree']">Web Solutions</div>
              </div>
            </div>

          
      </div>

      <style>
        {`
          @keyframes scroll {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-50%);
            }
          }
        `}
      </style>
    </div>
    </div>
  );
};

export default SuccessCasesDesk;