// import React from "react";
import React, { useState } from "react";

import Header from "./components/Header";
import BrandBuilding from "./components/BrandBuilding";
import SuccessCases from "./components/SuccessCases";
import WhyWorkWithUs from "./components/WhyWorkWithUs";
import Solutions from "./components/solutions/Solutions";
import Testimonials from "./components/Testimonials";
import MarketingPartnerSection from "./components/MarketingPartnerSection";
import Footer from "./components/Footer";
import Banner from "./components/Banner";
import SignUpModal from "./components/SignUpModal";
import SignInModal from "./components/SignInModal";

// test

function App() {
    // const [isSignUpOpen, setIsSignUpOpen] = useState(false);
    // const [isSignInOpen, setIsSignInOpen] = useState(false);

    // const openSignUp = () => {
    //     setIsSignInOpen(false);
    //     setIsSignUpOpen(true);
    // };

    // const openSignIn = () => {
    //     setIsSignUpOpen(false);
    //     setIsSignInOpen(true);
    // };

    // const closeModals = () => {
    //     setIsSignUpOpen(false);
    //     setIsSignInOpen(false);
    // };
  return (
    <div className="text-white font-sans">
      <Header  />
      {/* <SignUpModal isOpen={isSignUpOpen} onClose={closeModals} />
      <SignInModal isOpen={isSignInOpen} onClose={closeModals} /> */}
      
      <Banner />
      <BrandBuilding />
      <SuccessCases />
      <WhyWorkWithUs />
      <Solutions />
      <MarketingPartnerSection />
      <Testimonials />
      <Footer />
    </div>
  );
}

export default App;
