
import TestimonialsDesk from "./TestimonialsDesk";
import TestimonialsMb from "./TestimonialsMb";

const Testimonials = () => {
  return (
    <div class="testimonials-section">
      {/* Show MobileComponent on small screens and hide DesktopComponent */}
      <div className="block md:hidden">
        <TestimonialsMb />
      </div>

      {/* Show DesktopComponent on medium+ screens and hide MobileComponent */}
      <div className="hidden md:block sm:mx-auto  px-[20px]">
        <TestimonialsDesk />
      </div>
    </div>
  );
};

export default Testimonials;
