import React, { useState } from "react";

const CustomButton = ({ text }) => {
  return (
    <button className="relative w-[184px] h-[50.25px] rounded-full bg-white bg-opacity-20 text-white flex items-center justify-between hover:bg-gradient-to-r hover:from-blue-600 hover:to-green-400  transition-all duration-300">
      <span className="text-lg ms-[25.5px] text-white text-lg font-medium font-['Montserrat']">{text}</span>
      <div className="w-[37.11px] h-[37.11px] bg-white rounded-full  flex items-center justify-center me-[7.73px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-[18.56px] h-[18.56px] text-black"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          viewBox="0 0 24 24"
        >
          <path d="M5 12h13M12 5l7 7-7 7" />
        </svg>
      </div>
    </button>
  );
};

const NavigationArrow = ({ direction, onClick, isActive }) => {
  return (
    <button
      onClick={onClick}
      className={`relative rounded-full w-[34px] h-[34px] flex items-center justify-center shadow-lg transition duration-300 ${
        isActive ? "bg-white bg-opacity-20" : "bg-gray-300 bg-opacity-20"
      }  nav-s hover:bg-gray-500`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[18px] h-[18px] text-white"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        viewBox="0 0 24 24"
      >
        {direction === "left" ? (
          <path d="M19 12H6M12 19l-7-7 7-7" />
        ) : (
          <path d="M5 12h13M12 5l7 7-7 7" />
        )}
      </svg>
    </button>
  );
};

const CarouselDots = ({ slides, activeSlide, setActiveSlide }) => {
  return (
    <div className="flex justify-center space-x-3 place-items-center flex-auto">
      {slides.map((_, index) => (
        <span
          key={index}
          onClick={() => setActiveSlide(index)}
          className={`w-3 h-3 rounded-full cursor-pointer nav-s ${
            activeSlide === index
              ? "bg-green-500"
              : "bg-gray-500 hover:bg-green-500 transition duration-300"
          }`}
        ></span>
      ))}
    </div>
  );
};

const BrandBuildingMb = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [offset, setOffset] = useState(0);

  const slides = [
    [
      {
        title: "Strategic Partnership",
        description:
          "We function as an extension of your team, dedicated to achieving your success.",
        image: "./bb-slidemob-1.png",
        buttonText: "Get Started",
      },
    ],
    [
      {
        title: "Human + AI Energy",
        description:
          "Blending creative minds with cutting-edge technology to deliver superior results.",
        image: "./bb-slidemob-2.png",
        buttonText: "Learn More",
      },
    ],
    [
      {
        title: "Comprehensive Design",
        description:
          "From strategy to execution, we cover all aspects of your brand’s design and marketing needs.",
        image: "./bb-slidemob-3.png",
        buttonText: "Explore AI",
      },
    ],
    [
      {
        title: "Scalable & Predictable Pricing",
        description:
          "Fixed-cost solutions that grow with your business, ensuring scalability without surprises.",
        image: "./bb-slidemob-4.png",
        buttonText: "Get Started",
      },
    ],
    [
      {
        title: "AI Customer Portal 24/7",
        description:
          "Leverage our expertise to make a global impact with sustainable business solutions.",
        image: "./bb-slidemob-5.png",
        buttonText: "Get Started",
      },
    ]
  ];

  const handleNext = () => {
    setActiveSlide((prev) => (prev + 1) % slides.length);
  };

  const handlePrev = () => {
    setActiveSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.clientX);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    setOffset(e.clientX - startX);
  };

  const handleMouseUp = () => {
    if (Math.abs(offset) > 100) {
      if (offset < 0) {
        handleNext();
      } else {
        handlePrev();
      }
    }
    setIsDragging(false);
    setOffset(0);
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    setOffset(e.touches[0].clientX - startX);
  };

  const handleTouchEnd = () => {
    if (Math.abs(offset) > 100) {
      if (offset < 0) {
        handleNext();
      } else {
        handlePrev();
      }
    }
    setIsDragging(false);
    setOffset(0);
  };

  return (
    <div className="relative bg-transparent text-white flex flex-col bg-contain bg-no-repeat justify-center p-4" style={{ backgroundImage: "url('Enmascarar_grupo_2.png')" }}>
      <div className="w-full mx-auto text-left mr-12 my-[20px]">
        <div className="text-emerald-400 text-[18px] font-medium font-['Montserrat']">
          Where Strategy Drives Innovation:
        </div>
        <h6 className="text-white text-[35px] font-['Montserrat']">
          Building Exceptional Brands
        </h6>
      </div>

      <section className="relative w-full mx-auto overflow-hidden">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(calc(-${activeSlide * 80}% + ${offset}px))`, // Ajuste para mostrar el 20% del siguiente slide
            transition: isDragging ? "none" : "transform 0.5s ease",
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {slides.map((group, index) => (
            <div
              key={index}
              className="flex w-[80%] justify-center" // Cada slide ocupa el 80% del ancho
              style={{ minWidth: "80%" }} // Asegura que cada slide ocupe el 80% del ancho
            >
              {group.map((card, idx) => (
                <div
                  key={idx}
                  className="relative bg-gray-900 mr-[28px] rounded-[20px] border-2 border-green-500 shadow-lg overflow-hidden w-full h-[343.3px]"
                >
                  <img
                    src={card.image}
                    alt={card.title}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                  <div className="relative z-10 p-6 flex flex-col mt-20 h-full">
                    <h3 className="text-white text-[20px] font-medium font-['Montserrat']">
                      {card.title}
                    </h3>
                    <p className="text-stone-300 text-[14px] font-normal font-['Montserrat'] text-sm text-gray-300 mt-3 mb-3">
                      {card.description}
                    </p>
                    <CustomButton text={card.buttonText} />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className="flex justify-end mt-8 space-x-3">
          <CarouselDots slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
          <NavigationArrow direction="left" onClick={handlePrev} />
          <NavigationArrow direction="right" onClick={handleNext} />
        </div>
      </section>
    </div>
  );
};

export default BrandBuildingMb;