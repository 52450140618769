import React, { useState } from "react";

const CustomButton = ({ text }) => {
  return (
    <button className="relative w-[184px] h-[50.25px] rounded-full  bg-gray-700 text-white flex items-center justify-between hover:bg-gradient-to-r hover:from-blue-600 hover:to-green-400 transition-all duration-300">
      <span className="text-lg ms-[25.5px] text-white text-lg font-medium font-['Montserrat']">{text}</span>
      <div className="w-[37.11px] h-[37.11px] bg-white rounded-full flex items-center justify-center me-[7.73px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-[18.56px] h-[18.56px] text-black"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          viewBox="0 0 24 24"
        >
          <path d="M5 12h13M12 5l7 7-7 7" />
        </svg>
      </div>
    </button>
  );
};

const NavigationArrow = ({ direction, onClick }) => {
  const arrow = direction === "left" ? "←" : "→";
  return (
    <button
      onClick={onClick}
      className="bg-gray-700 nav-s hover:bg-gray-500 text-white rounded-full w-10 h-10 flex items-center justify-center shadow-lg transition duration-300"
    >
      {arrow}
    </button>
  );
};

const CarouselDots = ({ slides, activeSlide, setActiveSlide }) => {
  return (
    <div className="flex justify-center space-x-3 place-items-center	flex-auto	">
      {slides.map((_, index) => (
        <span
          key={index}
          onClick={() => setActiveSlide(index)}
          className={`w-3 h-3 rounded-full  cursor-pointer ${
            activeSlide === index
              ? "nav-sa"
              : "bg-gray-500 hover:bg-green-500 nav-s transition duration-300"
          }`}
        ></span>
      ))}
    </div>
  );
};

const BrandBuildingDesk = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const slides = [
    [
      {
        title: "Strategic Partnership",
        description:
          "We function as an extension of your team, dedicated to achieving your success.",
        image: "./2slide1.jpg",
        buttonText: "Get Started",
      },
      {
        title: "Human + AI Energy",
        description:
          "Blending creative minds with cutting-edge technology to deliver superior results.",
        image: "./2slide2.jpg",
        buttonText: "Get Started",
      },
      
    ],
    [
      {
        title: "Comprehensive Design",
        description:
          "From strategy to execution, we cover all aspects of your brand’s design and marketing needs.",
        image: "./2slide3.jpg",
        buttonText: "Get Started",
      },
      {
        title: "Scalable & Predictable Pricing",
        description:
          "Fixed-cost solutions that grow with your business, ensuring scalability without surprises.",
        image: "./2slide4.jpg",
        buttonText: "Get Started",
      },],
      [
      {
        title: "AI Customer Portal 24/7",
        description:
          "Leverage our expertise to make a global impact with sustainable business solutions.",
        image: "./2slide5.jpg",
        buttonText: "Get Started",
      },
      {
        title: "Future Trends",
        description:
          "Stay ahead with insights and strategies for upcoming market trends.",
        image: "./2slide3.jpg",
        buttonText: "Get Started",
      },
    ],
  ];

  const handleNext = () => {
    setActiveSlide((prev) => (prev + 1) % slides.length);
  };

  const handlePrev = () => {
    setActiveSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className=" w-[1285px] h-[490.87px] mx-auto bg-transparent text-white flex flex-col bg-contain bg-no-repeat  justify-center" >
      <div className="w-[1300px] mx-auto text-left mb-[50px] mr-12">
        <div className="text-green-400  text-left text-[30px]   font-medium font-['Montserrat']">
          Where Strategy Drives Innovation:
        </div>
        <div className="text-white text-[60px] font-medium font-['Montserrat']">
          Building Exceptional Brands
        </div>
      </div>
      <div class="enm"></div>

      <section className="relative w-full overflow-hidden">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${activeSlide * 90}%)` }}
        >
          {slides.map((group, index) => (
            <div key={index} className="flex w-[1280px] justify-start ">
              {group.map((card, idx) => (
                <div
                  key={idx}
                  className="relative bg-gray-900 rounded-2xl border-2 border-green-500 shadow-lg mr-[48px] overflow-hidden w-[558px] h-[244px]"
                >
                  <img
                    src={card.image}
                    alt={card.title}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                  <div className="relative z-10 p-6 flex flex-col justify-between h-full w-[70%]" >
                    <h3 className="text-white text-[25px] font-medium font-['Montserrat']">
                      {card.title}
                    </h3>
                    <p className="text-stone-400 font-normal  text-[18px] ">
                      {card.description}
                    </p>
                    <CustomButton text={card.buttonText} />
                  </div>
                  <div className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-transparent z-0"></div>
                </div>
              ))}
            </div>
          ))}
        </div>
        
        <div className="flex justify-end mt-[20px] space-x-3">
          <CarouselDots slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
          <NavigationArrow direction="left" onClick={handlePrev} />
          <NavigationArrow direction="right" onClick={handleNext} />
        </div>
      </section>
    </div>
  );
};

export default BrandBuildingDesk;
