
import FooterDesk from "./FooterDesk";
import FooterMb from "./FooterMb";

const Footer = () => {
  return (
    <div>
      {/* Show MobileComponent on small screens and hide DesktopComponent */}
      <div className="block md:hidden">
        <FooterMb />
      </div>

      {/* Show DesktopComponent on medium+ screens and hide MobileComponent */}
      <div className="hidden md:block">
        <FooterDesk />
      </div>
    </div>
  );
};

export default Footer;
