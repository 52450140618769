import React from "react";

const MarketingPartnerSectionDesk = () => {
  return (
    <div class="partner-section">
    <section className="relative mt-5 py-10 px-8 text-white mx-auto w-full max-w-[1235px] overflow-hidden h-[auto]">
      <div className="border-t-[2px] text-center border-gray-600 w-[50%] mx-auto py-10 g-sep"></div>
      {/* Radial Gradient Background */}
      <div className="absolute inset-0 z-0" style={{background: "radial-gradient(circle at 46% 63%, rgba(89, 59, 199, 0.53),rgba(0, 255, 128, 0.11), transparent 20%)", mixBlendMode: "lighten",}}></div>

      {/* Grid Layout */}
      <div className="container mx-auto grid lg:grid-cols-2 gap-8 items-start relative z-10">
        {/* Video Section */}
        <div className="w-full partner-video h-[280px] relative top-[25px]">
        <iframe
        className="w-full h-full rounded-lg"
        src="https://drive.google.com/file/d/1AVtr9a604hcxgNxGpQabXNaMWhx4_j5D/preview"
        title="Marketing Video"
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
      ></iframe>
        </div>

        {/* Text Section */}
        <div className="text-start lg:text-left">
          <h3 className="text-[25px] text-green-400 font-semibold mb-2">
            Meet Your Innovative
          </h3>
          <h2 className="text-4xl font-bold mb-4">
            Tech driven marketing partner for success
          </h2>
          <p className="text-gray-400 mb-4 p-4">
            Access top-tier talent from around the world—all through our
            intuitive portal.
          </p>
          <p className="text-gray-400 p-4">
            At <span className="text-white font-semibold">Art of Galaxy</span>,
            we deliver scalable, customized solutions designed to enhance brand
            awareness and drive business growth.
          </p>
        </div>
      </div>

    </section>
    </div>
  );
};

export default MarketingPartnerSectionDesk;