import { useState } from "react";

const HoverDrawer = () => {
  return (
    <div className="z-50 fixed bottom-10 right-10 flex flex-col space-y-3">
      {/* Planet Button with Hover Drawer */}
      <div className="relative group flex items-center">
        {/* Tooltip */}
        <div className="absolute text-fixed-icons right-0 flex items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gray-400 text-white text-sm py-2 px-3 rounded-full shadow-lg whitespace-nowrap">
          <span className="mr-2">Talk to us live</span>
          <div className="w-5 h-5 bg-blue-500 rounded-full flex items-center justify-center">
            <img src="planet-ringed-sharp-duotone-solid.png" alt="Planet" className="w-4 h-4" />
          </div>
        </div>
        
        
        {/* Button */}
        <button
          className="z-10 text-white p-3 rounded-full shadow-lg hover:bg-blue-600 bg-center bg-no-repeat w-[40px] h-[40px]"
          style={{ backgroundImage: `url('planet-ringed-sharp-duotone-solid.png')` }}
        />
      </div>

      {/* UFO Button with Hover Drawer */}
      <div className="relative group flex items-center">
        {/* Tooltip */}
        <div className="absolute text-fixed-icons right-0 flex items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gray-400 text-white text-sm py-2 px-3 rounded-full shadow-lg whitespace-nowrap">
            <span className="mr-2">Call us live</span>
          <div className="w-5 h-5 bg-green-500 rounded-full flex items-center justify-center">
            <img src="ufo-sharp-duotone-solid.png" alt="UFO" className="w-4 h-4" />
          </div>
        </div>
        
        {/* Button */}
        <button
          className="z-10 text-white p-3 rounded-full shadow-lg hover:bg-green-600 bg-center bg-no-repeat w-[40px] h-[40px]"
          style={{ backgroundImage: "url('ufo-sharp-duotone-solid.png')" }}
        />
      </div>
      <script 
          src="https://widgets.leadconnectorhq.com/loader.js"  
          data-resources-url="https://widgets.leadconnectorhq.com/chat-widget/loader.js" 
          data-widget-id="675b20221f70dceb7afabf6d">
        </script>
    </div>
    
  );
};

export default HoverDrawer;
