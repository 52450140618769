import React from "react";

const WhyWorkWithUsDesktop = () => {
  return (
    <section
  className="relative h-[1000px] text-white w-[100%] bg-no-repeat whywork-section"
  style={{
    backgroundImage: `linear-gradient(to bottom, #101010 0%, rgba(16, 16, 16, 0) 30%, rgba(16, 16, 16, 0) 80%, #101010 100%), url('earth_1.png')`,
    backgroundSize: "cover",
    backgroundPosition: "center"
  }}
>
      <div className="container  flex flex-col items-center justify-center  text-white  mx-auto">
        {/* Heading */}
        <div className="mb-[118px] g-80 text-center">  
          <h2 className="text-[60px] font-medium mb-[20px]">

            Why you should <span className="text-green-500">work with us?</span>
          </h2>
          <div className="text-gray-400 text-centered text-[20px] font-normal text-center w-[800px] h-[50px] ">
              <p >
                Whether you are launching a business, redefining your brand identity, or driving
                growth. Art of Galaxy is here to guide and support you every step of the way.
              </p>
             
          </div>

        </div>
        {/* Features */}
        <div className="grid md:grid-cols-3 gap-8 text-center mx-auto w-[1285px] h-[308px]">
          {/* Expert Team */}
          <div className="why-op rounded-lg p-6 ">
            <div className="flex justify-center items-center mb-[50px] g-why-icon">
              <img className="bg-white p-4 rounded-lg w-[67px] h-[67px]" src="team-building.png"></img>
            </div>
            <h3 className="text-[24px] font-medium mb-[16px]">Expert Team</h3>
            <p className="text-[16px] font-light w-[368px] h-[149px] text-['Poppins'] " >
              Our team of skilled strategists, visionary designers, marketing experts, and AI
              developers blends human creativity with advanced AI-powered insights. Together,
              we deliver innovative, impactful solutions that elevate your brand and make it
              stand out.
            </p>
          </div>

          {/* Tailored Solutions */}
          <div className="why-op rounded-lg p-6">
            <div className="flex justify-center items-center mb-[50px] ">
            <img className="bg-white p-4 rounded-lg w-[67px] h-[67px]" src="martillo.png"></img>
            </div>
            <h3 className="text-[24px] font-medium mb-[16px]">Tailored Solutions</h3>
            <p className="text-[16px] font-light w-[368px] h-[149px] text-['Poppins'] ">
              Your brand's story is unique, and so are our strategies. From comprehensive
              branding and state-of-the-art web development to focused social media
              management, we design customized solutions that align with your objectives and
              bring your vision to life.
            </p>
          </div>

          {/* Consistent Quality */}
          <div className="why-op rounded-lg p-6">
            <div className="flex justify-center items-center mb-[50px]">
            <img className="bg-white p-4 rounded-lg w-[67px] h-[67px]" src="medalla.png"></img>
            </div>
            <h3 className="text-[24px] font-medium mb-[16px]">Consistent Quality</h3>
            <p className="text-[16px] font-light w-[368px] h-[149px] text-['Poppins'] ">
              Excellence drives every aspect of our work. We approach each project with
              precision, creativity, and an unwavering dedication to producing results that
              exceed expectations and deliver lasting value.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyWorkWithUsDesktop;
