import React from 'react';

const SignUpModal = ({ onClose, onSwitchToSignIn }) => {
    return (
        <div className="galaxy-sing-in absolute right-0 mt-2 w-80 bg-white bg-opacity-30 backdrop-blur-md rounded-lg shadow-lg z-50 border-2 border-green-500">
            <div className="p-6">
                <h2 className="text-xl font-bold mb-4 text-left text-white">Join the future of creative collaboration</h2>
                <p className="text-xs text-gray-200 mb-6 text-left">Sign up and gain access to AI-enhanced tools, expert-driven solutions, and a streamlined platform to elevate your brand.</p>
                <form>
                    <div className="mb-3">
                        <input
                            type="text"
                            className="element-shadow w-full px-3 py-2 text-black border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 text-sm"
                            placeholder="Enter your full Name"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="email"
                            className="element-shadow w-full px-3 py-2 text-black border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 text-sm"
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="password"
                            className="element-shadow w-full px-3 py-2 text-black border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 text-sm"
                            placeholder="Enter a strong password"
                            required
                        />
                    </div>
                    <div className="mb-6">
                        <input
                            type="password"
                            className="element-shadow w-full px-3 py-2 text-black border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 text-sm"
                            placeholder="Re-enter your Password"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <button
                            type="submit"
                            className="w-full bg-gradient-to-r from-blue-600 to-green-400 text-white py-2 px-4 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-green-500 text-sm font-semibold"
                        >
                            Create Account
                        </button>
                    </div>
                    <div className="text-center text-xs text-gray-200 mb-4">
                        By signing up, you agree to our <a href="#" className="text-green-400 hover:text-gray-200">Terms of Service</a> and <a href="#" className="text-green-400 hover:text-gray-200">Privacy Policy</a>
                    </div>
                    <div className="flex items-center justify-center mb-6">
                        <div className="flex-grow border-t border-gray-300"></div>
                        <span className="mx-4 text-xs text-gray-200">Or continue with</span>
                        <div className="flex-grow border-t border-gray-300"></div>
                    </div>
                    <div className="flex  justify-center space-x-4 mb-6">
                        <button className="element-shadow social-button flex inset-shadow-sm inset-shadow-indigo-500 items-center justify-center w-full bg-transparent text-white py-2 px-4 rounded-md border-2 border-white hover:bg-gradient-to-r from-blue-600 to-green-400 hover:text-white transition-all duration-300 text-sm font-semibold">
                            <i className="fab fa-linkedin text-white mr-2"></i>
                            <span>LinkedIn</span>
                        </button>
                        <button className="element-shadow social-button flex inset-ring-4 ... items-center justify-center w-full bg-transparent text-white py-2 px-4 rounded-md border-2 border-white hover:bg-gradient-to-r from-blue-600 to-green-400 hover:text-white transition-all duration-300 text-sm font-semibold">
                            <i className="fab fa-google text-white mr-2"></i>
                            <span>Google</span>
                        </button>
                    </div>
                    <div className="text-center text-xs text-gray-200">
                        Already have an account? <button onClick={onSwitchToSignIn} className="text-green-400 hover:text-gray-200 font-semibold">Sign in</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignUpModal;