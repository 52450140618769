import React, { useRef, useState, useEffect } from "react";
import BrandBuildingMb from "./BrandBuildingMb";
import BrandBuildingDesk from "./BrandBuildingDesk";
const BrandBuilding = () => {


  return (
    <div class="building-brand-section">
      <div className="block md:hidden">
        <BrandBuildingMb />
      </div>
      <div className="hidden md:block sm:mx-auto lg:max-w-[1160px] xl:max-w-[1320px] 2xl:max-w-[1416px] px-[20px]">
        <BrandBuildingDesk />
      </div>
    </div>
  );
};

export default BrandBuilding;
