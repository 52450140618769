import React, { useState } from "react";
import "./Solutions.css";
import SolutionCard from "./SolutionCard";

const data = [
  {
    title: "Marketing Consulting",
    image: "marketing-consulting.jpg",
    content:
      "Marketing consulting provides expert guidance to optimize marketing strategies, improve campaign performance, and achieve measurable results.",
    details: [
      "Develop brand positioning to stand out in competitive markets",
      "Conduct market research to uncover trends and customer preferences",
      "Craft tailored campaign plans for target audiences",
      "Design marketing strategies aligned with business objectives",
    ],
  },
  {
    title: "Web Solutions",
    image: "web-solutions.jpg",
    content:
      "We understand that every brand is unique. Our customized CaaS packages are designed to meet your specific needs, whether you’re looking for comprehensive branding, cutting-edge web development, or strategic social media management.",
    details: [
      "UX/UI design for intuitive interfaces.",
      "Responsive designs for seamless cross-device performance",
      "E-commerce integration for streamlined shopping",
      "Analytics for tracking and improving performance",
      "SEO optimization to boost visibility and rankings",
    ],
  },
  {
    title: "Branding",
    image: "branding.jpg",
    content:
      "Branding creates a consistent identity that resonates with your audience. From visuals to guidelines, branding builds a unique presence and establishes recognition in competitive markets.",
    details: [
      "Create brand guidelines for consistent messaging and visuals",
      "Design visually appealing logos that embody your brand",
      "Build visual identity systems, including typography and color schemes",
      "Offer rebranding services to modernize your image",
    ],
  },
  {
    title: "Social Media Manager",
    image: "social-media-manager.jpg",
    content:
      "Social media management drives engagement and builds brand awareness through strategic content creation, campaign optimization, and performance tracking.",
    details: [
      "Craft platform-specific content for target audiences",
      "Use audience targeting to maximize reach and engagement",
      "Monitor metrics to refine campaigns and improve results",
      "Launch paid ad campaigns to generate leads and conversions",
    ],
  },
  {
    title: "Email Marketing",
    image: "email-marketing.jpg",
    content: "Email marketing uses personalized campaigns to nurture leads, increase engagement, and drive conversions. Automated and strategic, it creates lasting customer connections.",
    details: [
      "Design emails that align with your brand",
      "Segment lists for targeted messaging",
      "Write engaging content to capture attention",
      "Track and optimize performance for better results",
    ],
  },
  {
    title: "B2B & B2C Campagins",
    image: "B2B-and-B2C-campagins.jpg",
    content:
      "Marketing campaigns for B2B and B2C audiences boost visibility, lead generation, and sales by combining creative strategies with measurable results.",
    details: [
      "Develop strategic plans aligned with business goals",
      "Create eye-catching ads to attract attention",
      "Execute promotional campaigns to enhance visibility",
      "Conduct A/B testing to optimize performance",
    ],
  },
  {
    title: "AI Integrations & Automations",
    image: "AI-integrations-and-automations.jpg",
    content:
      "Enhance operations and customer interactions with advanced AI solutions, improving efficiency and scalability across key platforms.",
    details: [
      "Deploy AI chatbots for 24/7 support on websites, SMS, Instagram, Facebook, WhatsApp, and more",
      "Automate workflows to save time and streamline processes",
      "Use AI voice agents for seamless customer support, appointment scheduling and more",
      "Integrate AI-powered CRM systems to optimize lead management and sales pipelines",
    ],
  },
]


const defaultDisplay = [
    {
      gridColumn: "1 / 8",
      gridRow: "1 / 10",
    }, 
    {
      gridColumn: "9 / 21",
      gridRow: "1 / 8",
    },
    {
      gridColumn: "1 / 8",
      gridRow: "11 / 18",
    },
    {
      gridColumn: "9 / 14",
      gridRow: "9 / 18",
    },
    {
      gridColumn: "15 / 21",
      gridRow: "9 / 18",
    },
    {
      gridColumn: "1 / 12",
      gridRow: "19 / 28",
    },
    {
      gridColumn: "13 / 21",
      gridRow: "19 / 28",
    }
];

const Solutions = () => {
  const [displayPosition, setDisplayPosition] = useState(defaultDisplay);
  const [activeCard, setActiveCard] = useState(-1);

  const handleClick = (index) => {
    console.log(index);
    setActiveCard(index);
    const newDisplay = JSON.parse(JSON.stringify(defaultDisplay));;
    switch (index) {
      case 0:
        newDisplay[0].gridColumn = "1 / 14";
        newDisplay[0].gridRow = "1 / 9";
        newDisplay[1].gridColumn = "15 / 21";
        newDisplay[1].gridRow = "1 / 12";
        newDisplay[2].gridRow = "10 / 18";
        newDisplay[3].gridRow = "10 / 18";
        newDisplay[4].gridRow = "13 / 18";
        break;
      case 1:
        newDisplay[1].gridRow = "1 / 10";
        newDisplay[3].gridRow = "11 / 18";
        newDisplay[4].gridRow = "11 / 18";
        break;
      case 2:
        newDisplay[0].gridRow = "1 / 8";
        newDisplay[2].gridRow = "9 / 18";
        newDisplay[2].gridColumn = "1 / 11";
        newDisplay[3].gridColumn = "12 / 16";
        newDisplay[4].gridColumn = "17 / 21";
        break;
      case 3:
        
        newDisplay[0].gridColumn = "1 / 7";
        newDisplay[1].gridColumn = "8 / 21";
        newDisplay[1].gridRow = "1 / 9";
        newDisplay[2].gridColumn = "1 / 7";
        newDisplay[3].gridColumn = "8 / 17";
        newDisplay[3].gridRow = "10 / 18";
        newDisplay[4].gridColumn = "18 / 21";
        newDisplay[4].gridRow = "10 / 18";
        if (isTablet())
        {
          newDisplay[3].gridColumn = "7 / 16";
          newDisplay[0].gridColumn = "1 / 6";
          newDisplay[2].gridColumn = "1 / 6";
          newDisplay[1].gridColumn = "7 / 21";
          newDisplay[4].gridColumn = "17 / 21";
          newDisplay[4].gridRow = newDisplay[3].gridRow = "10 / 19";
          newDisplay[2].gridRow = "11 / 19";
          newDisplay[5].gridRow = newDisplay[6].gridRow = "20 / 28";
        }
        break;
      case 4:
        newDisplay[0].gridColumn = "1 / 7";
        newDisplay[1].gridColumn = "8 / 21";
        newDisplay[2].gridColumn = "1 / 7";
        newDisplay[3].gridColumn = "8 / 12";
        newDisplay[4].gridColumn = "13 / 21";
        break;
      case 6:
        newDisplay[3].gridRow = "9 / 19";
        newDisplay[4].gridRow = "9 / 19";
        newDisplay[5].gridColumn = "1 / 8";
        newDisplay[6].gridColumn = "9 / 21";
        newDisplay[6].gridRow =  "20 / 28";
        break;
      default:
        break;
    }

    setDisplayPosition(newDisplay);
  }

  return (
    <div className="sm:mx-auto lg:max-w-[1160px] xl:max-w-[1320px] 2xl:max-w-[1416px] px-[20px] solutions-section">
      <section className="text-white min-h-screen">
        {/* Header */}
        <header className="text-center mb-10 relative z-10">
          <h1 className="font-medium md:text-[60px] text-[35px]"> <span className="text-green-500">Solutions</span> for growth</h1>
          <p className="text-[16px] md:text-[20px] md:text-gray-400 mt-4 max-w-[800px] mx-auto">
            Whether you are starting a new venture as a start-up, looking to refresh your current
            image, or striving for growth, we are here to nurture your brand in achieving your
            goals at every step of your brand journey.
          </p>
        </header>

        {/* Grid Section */}
        <div className="solutions-grid">
          {/* Card Components */}
          {
            data.map((card, index) =>
              (<SolutionCard active={(activeCard === index)} key={index} card={card} position={displayPosition[index]} onClick={() => handleClick(index)} />)
            )
          }

          
        </div>
        
      </section>
    </div>

  );
};

const isTablet = () => {
  const highDPI = window.matchMedia('(-webkit-min-device-pixel-ratio: 2)').matches;
  const wideScreen = window.matchMedia('(min-width: 768px)').matches;

  if (highDPI && wideScreen) {
    console.log('High DPI and wide screen');
  }
  
  return highDPI && wideScreen;
}
export default Solutions;
