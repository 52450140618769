
import HeaderDesk from "./HeaderDesk";
import HeaderMb from "./HeaderMb";

const Header = () => {
  return (
    <div>
      {/* Show MobileComponent on small screens and hide DesktopComponent */}
      <div className="block md:hidden">
        <HeaderMb />
      </div>

      {/* Show DesktopComponent on medium+ screens and hide MobileComponent */}
      <div className="hidden md:block">
        <HeaderDesk />
      </div>
    </div>
  );
};

export default Header;
