import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonialsData = [
  {
    name: "Brooklyn Simmons",
    image: "imgs5.png",
    text: "They approached our branding project with a focus on long- term growth, not just aesthetics. The new brand identity has helped us stand out in a competitive market, and we're now attracting the clients we've always wanted. ",
    rating: 5,
  },
  {
    name: "Jerome Bell",
    image: "imgs6.png",
    text: "I was nervous about rebranding, but the team at Art of Galaxy made it so easy. They really listened to what we wanted, and the new logo? Perfect. Our customers keep saying how much they love it.",
    rating: 5,
  },
  {
    name: "Kristin Watson",
    image: "imgs4.png",
    text: "I thought fancy packaging was just for big brands, but w they showed me how much of a difference it makes. Now products practically fly off the shelves!It's clear they understand the B2B space.",
    rating: 5,
  },
  {
    name: "Darrell Steward",
    image: "imgs3.png",
    text: "Their social media strategies have been nothing short of phenomenal. Our engagement rates tripled within three months, and their campaign ideas always strike the perfect chord with our audience.",
    rating: 5,
  }, 
  {
    name: "Carol Strada",
    image: "imgs2.png",
    text: "We had no idea how much better our presentations could look until they redesigned them. Now, every pitch feels like a polished performance. The feedback from clients has been amazing, and it's helped us close some big deals.",
    rating: 5,
  }, 
  {
    name: "Jhon Strain",
    image: "imgs1.png",
    text: "Our old website was like an online brochure, boring and hard to navigate. Art of Galaxy gave us a site that's not only beautiful but super easy for customers to use. We've already seen more inquiries coming in.",
    rating: 5,
  }, 
  {
    name: "Javier Martinez",
    image: "imgs7.png",
    text: "Before, our social media was... let's just say, 'meh.' Now, it's full of life! Their posts get people talking, and I've even had customers tell me they discovered us on Instagram. Thanks, guys",
    rating: 5,
  }, 
];

const TestimonialsMb = () => {
  if (!testimonialsData || testimonialsData.length === 0) {
    return <div className="text-center text-gray-400">No testimonials available.</div>;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: true,
    appendDots: (dots) => (
      <div className="mt-10">
        <ul className="flex justify-center  space-x-2">{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div className="w-3 h-3 rounded-full nav-s transition-all duration-300"></div>
    ),
    afterChange: (current) => {
      setTimeout(() => {
        const dots = document.querySelectorAll(".slick-dots li div");
        if (dots) {
          dots.forEach((dot, index) => {
            dot.style.backgroundColor = index === current ? "#3c3c3c" : "#555"; // Green active, Gray inactive
          });
        }
      }, 50);
    },
  };

  return (
  
    <div className="text-mob w-full flex justify-center items-center px-4 py-10">
        <div className="text-center my-10">
        <h2 className="text-4xl font-semibold">
          What our <span className="text-green-400">clients say</span> about us
        </h2>
      </div>
      <div className="w-full max-w-md mx-auto mt-4">
        <Slider {...settings}>
          {testimonialsData.map((testimonial, index) => (
            <div key={index} className="p-1 mb-10">
              <div className="p-3 rounded-xl shadow-lg relative h-[350px] flex flex-col justify-evenly ">
                
                {/* Profile Image & Name */}
                <div className="flex items-center space-x-4">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-12 h-12 rounded-full border border-gray-500"
                  />
                  <h3 className="text-white text-md font-semibold">{testimonial.name}</h3>
                </div>

                {/* Testimonial Text */}
                <p className="text-sm text-gray-400 mt-4 leading-6">
                  {testimonial.text}
                </p>

                {/* Rating + Arrow */}
                <div className="flex items-center justify-between mt-4">
                  <div className="text-yellow-400 text-lg">
                    {"★".repeat(testimonial.rating)}
                    {"☆".repeat(5 - testimonial.rating)}
                  </div>
                  <div className="text-gray-600 text-bold arrow-testi text-3xl cursor-pointer hover:text-white transition">
                  🡕
                  </div>
                </div>

                {/* Rounded Brackets for Styling */}
                <div className="absolute top-0 left-0 w-8 h-8 border-t-2 border-l-2 border-gray-600 rounded-tl-2xl"></div>
                <div className="absolute top-0 right-0 w-8 h-8 border-t-2 border-r-2 border-gray-600 rounded-tr-2xl"></div>
                <div className="absolute bottom-0 left-0 w-8 h-8 border-b-2 border-l-2 border-gray-600 rounded-bl-2xl"></div>
                <div className="absolute bottom-0 right-0 w-8 h-8 border-b-2 border-r-2 border-gray-600 rounded-br-2xl"></div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonialsMb;
