import React, { useState, useEffect } from "react";

const BannerDesk = ({ isDraggable = true }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoLink, setVideoLink] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isDragging) {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
      }
    }, 10000000000);
    return () => clearInterval(interval);
  }, [isDragging]);

  // Eventos para mouse
  const handleMouseDown = (e) => {
    if (!isDraggable) return;
    startDrag(e.clientX);
  };

  const handleMouseMove = (e) => {
    if (!isDraggable || !isDragging) return;
    updateDrag(e.clientX);
  };

  const handleMouseUp = () => {
    endDrag();
  };

  // Eventos para touch
  const handleTouchStart = (e) => {
    if (!isDraggable) return;
    startDrag(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!isDraggable || !isDragging) return;
    updateDrag(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    endDrag();
  };

  // Lógica común de arrastre
  const startDrag = (clientX) => {
    setIsDragging(true);
    setStartX(clientX);
    setIsTransitioning(false);
  };

  const updateDrag = (clientX) => {
    setOffset(clientX - startX);
  };

  const endDrag = () => {
    if (!isDraggable) return;

    if (Math.abs(offset) > 100) {
      setIsTransitioning(true);
      if (offset < 0) {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
      } else {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
      }
    }

    setIsDragging(false);
    setOffset(0);
  };

  const openModal = (link) => {
    setVideoLink(`${link}?autoplay=1`);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTimeout(() => setVideoLink(""), 300);
  };

  const slides = [
    {
      id: 1,
      title: "Innovate Your Brand with Art of Galaxy",
      subtitle:
        "We empower Businesses with Scalable Design and Marketing Solutions, Driven by Human Expertise and Advanced AI for Unmatched Brand Growth.",
      buttonText: "Get Started",
      background: isMobile ? "Mobile_slider_2.png" : "/slide1.jpg",
      videoLink: "https://drive.google.com/file/d/1AVtr9a604hcxgNxGpQabXNaMWhx4_j5D/preview?embedded=true"
    },
    {
      id: 2,
      title: "Innovate Your Brand with Art of Galaxy",
      subtitle: "We empower Businesses with Scalable Design and Marketing Solutions, Driven by Human Expertise and Advanced AI for Unmatched Brand Growth.",
      buttonText: "Get Started",
      background: isMobile ? "Mobile_slider_1.png" : "/slide2.jpg",
      videoLink: "https://drive.google.com/file/d/1AVtr9a604hcxgNxGpQabXNaMWhx4_j5D/preview?embedded=true"
    },
    {
      id: 3,
      title: "Innovate Your Brand with Art of Galaxy",
      subtitle: "We empower Businesses with Scalable Design and Marketing Solutions, Driven by Human Expertise and Advanced AI for Unmatched Brand Growth.",
      buttonText: "Get Started",
      background: isMobile ? "Mobile_slider_3.png" : "/slide3.jpg",
      videoLink: "https://drive.google.com/file/d/1AVtr9a604hcxgNxGpQabXNaMWhx4_j5D/preview?embedded=true"
    },
    {
      id: 4,
      title: "Innovate Your Brand with Art of Galaxy",
      subtitle: "We empower Businesses with Scalable Design and Marketing Solutions, Driven by Human Expertise and Advanced AI for Unmatched Brand Growth.",
      buttonText: "Get Started",
      background: isMobile ? "Mobile_slider_4.png" : "/slide4.jpg",
      videoLink: "https://drive.google.com/file/d/1AVtr9a604hcxgNxGpQabXNaMWhx4_j5D/preview?embedded=true"
    },
  ];

  return (
    <div className="relative w-full h-[1077px] overflow-hidden galaxy-banner">
      <div
        className="flex w-full h-full transition-transform duration-500 ease-in-out"
        style={{
          transform: `translateX(calc(-${currentSlide * 100}% + ${offset}px))`,
          transition: isDragging ? "none" : "transform 0.5s ease",
        }}
        onMouseDown={isDraggable ? handleMouseDown : undefined}
        onMouseMove={isDraggable ? handleMouseMove : undefined}
        onMouseUp={isDraggable ? handleMouseUp : undefined}
        onMouseLeave={isDraggable ? handleMouseUp : undefined}
        onTouchStart={isDraggable ? handleTouchStart : undefined}
        onTouchMove={isDraggable ? handleTouchMove : undefined}
        onTouchEnd={isDraggable ? handleTouchEnd : undefined}
      >
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className="w-full flex-shrink-0 h-full bg-cover bg-center"
            style={{ backgroundImage: `linear-gradient(to top, #101010 5%, transparent 20%), url(${slide.background})` }}
          >
            <div className="flex flex-col items-start w-[1280px] mx-auto text-white h-full justify-center">
              <h1 className="text-[77px] font-semibold mb-[30px]">
                <span className="text-green-400">{slide.title.split(" ")[0]}{" "}</span>
                <span className="text-white">{slide.title.split(" ").slice(1).join(" ")}</span>
              </h1>
              <p className="text-[20px] font-normal text-white mb-[80px]">{slide.subtitle}</p>
              <div className="flex space-x-4 items-center">
                <button className="relative w-[184px] h-[45.25px] rounded-full bg-gray-700 text-white flex items-center space-x-3 hover:bg-gradient-to-r hover:from-blue-600 hover:to-green-400 transition-all duration-300">
                  <span className="text-xl ms-[18px]">{slide.buttonText}</span>
                  <div className="w-[37.11px] h-[37.11px] bg-white rounded-full  flex items-center justify-center me-[7.73px] mt-[6.96px] mb-[6.18px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-[18.56px] h-[18.56px] text-black"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h13M12 5l7 7-7 7" />
                    </svg>
                  </div>
                </button>

                {/* Play Button */}
                <button
                  onClick={() => openModal(slide.videoLink)}
                  className="flex items-center justify-center w-10 h-10 bg-gradient-to-r from-blue-600 to-green-400 rounded-full hover:scale-110 transform transition-all duration-300 shadow-lg"
                >
                  <svg className="w-5 h-5 text-white" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M5 3v18l15-9L5 3z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Paginación */}
      <div className="absolute right-10 top-1/2 pag-ga-cont transform -translate-y-1/2 flex flex-col items-center space-y-3">
        {slides.map((_, index) => (
          <div
            key={index}
            onClick={() => setCurrentSlide(index)}
            className={`pagination-g cursor-pointer h-1 ${index === currentSlide ? "w-10 bg-white" : "w-6 bg-gray-400"} rounded transition-all duration-300`}
          ></div>
        ))}
      </div>

      {/* Modal de Video */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="relative w-3/4 h-3/4 bg-black rounded-lg overflow-hidden banner-video">
            <iframe src={videoLink} title="Video" className="w-full h-full" frameBorder="0"></iframe>
            <button onClick={closeModal} className="absolute top-0 right-0 text-white bg-red-500 p-2 rounded-full close-video">✕</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BannerDesk;