import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/TestimonialsDesk.css";

const testimonialsData = [
  {
    name: "Brooklyn Simmons",
    image: "imgs5.png",
    text: "They approached our branding project with a focus on long- term growth, not just aesthetics. The new brand identity has helped us stand out in a competitive market, and we're now attracting the clients we've always wanted. ",
    rating: 5,
  },
  {
    name: "Jerome Bell",
    image: "imgs6.png",
    text: "I was nervous about rebranding, but the team at Art of Galaxy made it so easy. They really listened to what we wanted, and the new logo? Perfect. Our customers keep saying how much they love it.",
    rating: 5,
  },
  {
    name: "Kristin Watson",
    image: "imgs4.png",
    text: "I thought fancy packaging was just for big brands, but w they showed me how much of a difference it makes. Now products practically fly off the shelves!It's clear they understand the B2B space.",
    rating: 5,
  },
  {
    name: "Darrell Steward",
    image: "imgs3.png",
    text: "Their social media strategies have been nothing short of phenomenal. Our engagement rates tripled within three months, and their campaign ideas always strike the perfect chord with our audience.",
    rating: 5,
  }, 
  {
    name: "Carol Strada",
    image: "imgs2.png",
    text: "We had no idea how much better our presentations could look until they redesigned them. Now, every pitch feels like a polished performance. The feedback from clients has been amazing, and it's helped us close some big deals.",
    rating: 5,
  }, 
  {
    name: "Jhon Strain",
    image: "imgs1.png",
    text: "Our old website was like an online brochure, boring and hard to navigate. Art of Galaxy gave us a site that's not only beautiful but super easy for customers to use. We've already seen more inquiries coming in.",
    rating: 5,
  }, 
  {
    name: "Javier Martinez",
    image: "imgs7.png",
    text: "Before, our social media was... let's just say, 'meh.' Now, it's full of life! Their posts get people talking, and I've even had customers tell me they discovered us on Instagram. Thanks, guys",
    rating: 5,
  }, 
];

const TestimonialsDesk = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: true,
    initialSlide: 0,
    centerMode: false,
    customPaging: (i) => (
      <div className="w-3 h-3 rounded-full nav-s transition-all duration-300" />
    ),
    afterChange: (current) => {
      const dots = document.querySelectorAll(".slick-dots li div");
      dots.forEach((dot, index) => {
        dot.style.backgroundColor = index === current ? "#3c3c3c" : "whiet";
      });
    },
    responsive: [
      { breakpoint: 1280, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <div className="py-10 w-full text-white">
      <div className="text-center my-10">
        <h2 className="text-4xl font-semibold">
          What our <span className="text-green-400">clients say</span> about us
        </h2>
      </div>
      <div className="w-full mx-auto"> {/* Increased container width to accommodate 3 items */}
        <Slider {...settings}>
          {testimonialsData.map((testimonial, index) => (
            <div key={index} className="p-2">
              <div className="mx-auto p-10 rounded-lg shadow-lg relative h-auto flex flex-col justify-between items-start space-y-4">
                {/* Profile Image & Name */}
                <div className="flex items-center space-x-4">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-14 h-14 rounded-full border-gray-300"
                  />
                  <h3 className="text-lg ">{testimonial.name}</h3>
                </div>

                {/* Testimonial Text */}
                <p className="text-gray-400 mb-4">{testimonial.text}</p>

                {/* Rating & Arrow */}
                <div className="flex items-center justify-between w-full">
                  <div className="text-yellow-400 text-lg">
                    {"★".repeat(testimonial.rating)}
                    {"☆".repeat(5 - testimonial.rating)}
                  </div>
                  <div className="text-gray-400 text-xl testimonial-arrow">🡕</div>
                </div>

                {/* Corner Brackets */}
                <div className="absolute top-0 left-0 w-6 h-6 border-t-2 border-l-2 border-gray-500 rounded-tl-md"></div>
                <div className="absolute top-0 right-0 w-6 h-6 border-t-2 border-r-2 border-gray-500 rounded-tr-md"></div>
                <div className="absolute bottom-0 left-0 w-6 h-6 border-b-2 border-l-2 border-gray-500 rounded-bl-md"></div>
                <div className="absolute bottom-0 right-0 w-6 h-6 border-b-2 border-r-2 border-gray-500 rounded-br-md"></div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonialsDesk;