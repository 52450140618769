
const SolutionCard = ({ index , card, position, onClick, active }) => {
  let content = <></>;
  if (active) 
  {
    content = <>
     <p className="mb-8 md:mb-4">{card.content}</p>

      {/* Details */}
      <ul className="space-y-4">
        {card.details.map((detail, idx) => (
          <li
            key={idx}
            className="my-2"
          >
            <button>
            <span className="text-left">{detail}</span>
            <span className="ml-2 text-black">➔</span>
            </button>
          </li>
        ))}
      </ul>
    </>
  }

  return (
    <div
    key={index}
    className={`solution-card overflow-hidden ${active ? 'active' : ''}`}
    style={position}
    onClick={onClick}
  >
    {/* Card Image */}
    <img
      src={card.image}
      alt={card.title}
      className="w-full h-full object-cover"
    />

    {/* Overlay Content */}
    <div className={`solution-card-overlay absolute p-4 ${active ? 'active' : 'pt-[40px]'}`}>
      {/* Title */}
      <h3>{card.title}</h3>
      
      {content}
     
    </div>
  </div>
  )
}

export default SolutionCard