import React from 'react';

const SignInModal = ({ onClose, onSwitchToSignUp }) => {
    return (
        <div className="absolute right-0 mt-2 w-80 galaxy-sing-in bg-gradient-to-b from-purple-300 to-green-300 backdrop-blur-3xl rounded-lg shadow-lg z-50 border-2 border-green-500">
            <div className="p-6 ">
                <h2 className="text-xl font-bold mb-4 text-left text-white">Join the future of creative collaboration</h2>
                <p className="text-xs text-gray-200 mb-6 text-left">Log in to revolutionize your creative strategy!</p>
                <form>
                    <div className="mb-3">
                        <input
                            type="email"
                            className="element-shadow w-full px-3 py-2 text-black border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 text-sm"
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <div className="mb-1">
                        <input
                            type="password"
                            className="element-shadow w-full px-3 py-2 text-black border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 text-sm"
                            placeholder="Enter your password"
                            required
                        />
                    </div>
                    <div className="text-left mb-2">
                        <a href="#" className="text-xs text-green-400 hover:text-gray-200">Forgot your password?</a>
                    </div>
                    <div className="mb-4">
                        <button
                            type="submit"
                            className="w-full bg-gradient-to-r from-blue-600 to-green-400 text-white py-2 px-4 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-green-500 text-sm font-semibold"
                        >
                            Sign in
                        </button>
                    </div>
                    <div className="flex items-center justify-center mb-6">
                        <div className="flex-grow border-t border-gray-300"></div>
                        <span className="mx-4 text-xs text-gray-200">Or continue with</span>
                        <div className="flex-grow border-t border-gray-300"></div>
                    </div>
                    <div className="flex justify-center space-x-4 mb-6">
                        <button className="element-shadow social-button flex inset-shadow-sm items-center justify-center w-full bg-transparent text-white py-2 px-4 rounded-md border-2 border-white hover:bg-gradient-to-r from-blue-600 to-green-400 hover:text-black transition-all duration-300 text-sm font-semibold">
                            <i className="fab fa-linkedin text-white mr-2"></i>
                            <span>LinkedIn</span>
                        </button>
                        <button className="element-shadow social-button flex inset-shadow-sm items-center justify-center w-full bg-transparent text-white py-2 px-4 rounded-md border-2 border-white hover:bg-gradient-to-r from-blue-600 to-green-400 hover:text-black transition-all duration-300 text-sm font-semibold">
                            <i className="fab fa-google text-white mr-2"></i>
                            <span>Google</span>
                        </button>
                    </div>
                    <div className="text-center text-xs text-gray-200">
                        Don't have an account? <button onClick={onSwitchToSignUp} className="text-green-400 hover:text-gray-200 font-semibold">Sign up</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignInModal;